<template>
  <div class="sild">
    <div class="box">
      <swiper :options="swiperOption" ref="mySwiper" v-if="coverUrls">
        <!-- 添加的图片 -->
        <swiper-slide class="swiper-slidebox">
          <img class="swiper-slide" :src="coverUrls[0]" />
          <div
            class="text"
            @mouseover="showDetails(0)"
            @mouseleave="hideDetails"
          >
            <div class="title">
              <div class="titlehead">
                <img :src="icons[0]" />
                <div>
                  {{ titles[0] }}
                </div>
              </div>
              <div v-if="showDetailIndex === 0" class="detail-box">
                <div v-html="contents[0]"></div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slidebox">
          <img class="swiper-slide" :src="coverUrls[1]" />
          <div
            class="text"
            @mouseover="showDetails(1)"
            @mouseleave="hideDetails"
          >
            <div class="title">
              <div class="titlehead">
                <img :src="icons[1]" />
                <div>
                  {{ titles[1] }}
                </div>
              </div>
              <div v-if="showDetailIndex === 1" class="detail-box">
                <div v-html="contents[1]"></div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slidebox">
          <img class="swiper-slide" :src="coverUrls[2]" />
          <div
            class="text"
            @mouseover="showDetails(2)"
            @mouseleave="hideDetails"
          >
            <div class="title">
              <div class="titlehead">
                <img :src="icons[2]" />
                <div>
                  {{ titles[2] }}
                </div>
              </div>
              <div v-if="showDetailIndex === 2" class="detail-box">
                <div v-html="contents[2]"></div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slidebox">
          <img class="swiper-slide" :src="coverUrls[3]" />
          <div
            class="text"
            @mouseover="showDetails(3)"
            @mouseleave="hideDetails"
          >
            <div class="title">
              <div class="titlehead">
                <img :src="icons[3]" />
                <div>
                  {{ titles[3] }}
                </div>
              </div>
              <div v-if="showDetailIndex === 3" class="detail-box">
                <div v-html="contents[3]"></div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slidebox">
          <img class="swiper-slide" :src="coverUrls[4]" />
          <div
            class="text"
            @mouseover="showDetails(4)"
            @mouseleave="hideDetails"
          >
            <div class="title">
              <div class="titlehead">
                <img :src="icons[4]" />
                <div>
                  {{ titles[4] }}
                </div>
              </div>
              <div v-if="showDetailIndex === 4" class="detail-box">
                <div v-html="contents[4]"></div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slidebox" v-if="coverUrls[5]">
          <div
            class="text"
            @mouseover="showDetails(5)"
            @mouseleave="hideDetails"
          >
            <div class="title">
              <div class="titlehead">
                <img :src="icons[5]" />
                <div>
                  {{ titles[5] }}
                </div>
              </div>
              <div v-if="showDetailIndex === 5" class="detail-box">
                <div v-html="contents[5]"></div>
              </div>
            </div>
          </div>
          <img class="swiper-slide" :src="coverUrls[5]" />
        </swiper-slide>
        <swiper-slide class="swiper-slidebox" v-if="coverUrls[6]">
          <div
            class="text"
            @mouseover="showDetails(6)"
            @mouseleave="hideDetails"
          >
            <div class="title">
              <div class="titlehead">
                <img :src="icons[6]" />
                <div>
                  {{ titles[6] }}
                </div>
              </div>
              <div v-if="showDetailIndex === 6" class="detail-box">
                <div v-html="contents[6]"></div>
              </div>
            </div>
          </div>
          <img class="swiper-slide" :src="coverUrls[6]" />
        </swiper-slide>
        <swiper-slide class="swiper-slidebox" v-if="coverUrls[7]">
          <div
            class="text"
            @mouseover="showDetails(7)"
            @mouseleave="hideDetails"
          >
            <div class="title">
              <div class="titlehead">
                <img :src="icons[7]" />
                <div>
                  {{ titles[7] }}
                </div>
              </div>
              <div v-if="showDetailIndex === 7" class="detail-box">
                <div v-html="contents[7]"></div>
              </div>
            </div>
          </div>
          <img class="swiper-slide" :src="coverUrls[7]" />
        </swiper-slide>
        <swiper-slide class="swiper-slidebox" v-if="coverUrls[8]">
          <div
            class="text"
            @mouseover="showDetails(8)"
            @mouseleave="hideDetails"
          >
            <div class="title">
              <div class="titlehead">
                <img :src="icons[8]" />
                <div>
                  {{ titles[8] }}
                </div>
              </div>
              <div v-if="showDetailIndex === 8" class="detail-box">
                <div v-html="contents[8]"></div>
              </div>
            </div>
          </div>
          <img class="swiper-slide" :src="coverUrls[8]" />
        </swiper-slide>
      </swiper>
    </div>
    <!-- <div class="swiper-pagination"></div> -->
    <div
      class="swiper-button-prev swiper-button-white"
      slot="button-prev"
    ></div>
    <div
      class="swiper-button-next swiper-button-white"
      slot="button-next"
    ></div>
  </div>
</template>
  
  <script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { getIndustryApplication } from "@/service/api";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      showDetailIndex: null,
      data: {},
      coverUrls: [],
      contents: [],
      titles: [],
      icons: [],
      swiperOption: {
        //可见图片张数
        slidesPerView: 4,
        // 默认选中中间一张
        // centeredSlides: true,
        //间隙
        // spaceBetween: 0,
        //无限循环
        // loop: true,
        //小圆点（我此处没使用，列出供参考）
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        //上下按钮点击轮播效果
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  computed: {},
  mounted() {
    this.Banner();
  },
  methods: {
    async Banner() {
      this.data = (await getIndustryApplication()) || {};
      this.coverUrls = this.data.map((product) => product.coverUrl);
      this.contents = this.data.map((product) => product.content);
      this.titles = this.data.map((product) => product.title);
      this.icons = this.data.map((product) => product.icon);
      // console.log(this.data);
      // console.log(this.coverUrls);
    },
    showDetails(index) {
      this.showDetailIndex = index;
    },
    hideDetails() {
      this.showDetailIndex = null;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";
img {
  vertical-align: top;
}
.sild {
  position: relative;
}
.box {
  width: 100%;
  overflow: hidden;
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  .swiper-slidebox {
    position: relative;
    .text {
      position: absolute;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      font-size: 25px;
      color: #fff;
      display: flex;
      flex-direction: column-reverse; /* 从下至上排列 */
      align-items: center;
      .title {
        width: 100%;
        .titlehead {
          margin-bottom: 50px;
          text-align: center;
          img {
            margin-bottom: 14px;
          }
        }
        .detail-box {
          align-items: center;
          width: 100%;
          font-size: 16px;
          padding: 0 15%;
          padding-bottom: 40px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            #000000 100%
          );
        }
      }
    }
  }
}
</style>