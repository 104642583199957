<template>
  <div class="wrap" v-show="lang">
    <Header />
    <home />
    <Footer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import home from "./components/home";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    home,
  },
  computed: {
    ...mapState(["lang"]),
    isZhCN() {
      const isCN = this.lang === "zh-CN";
      return isCN;
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/util.scss";

.wrap {
  @extend .iphoneX;
}
</style>
