<template>
  <div class="tab-class-detail">
    <el-carousel
      :interval="5000"
      :autoplay="true"
      height="46.5vw"
      trigger="click"
      class="carousel-container"
    >
      <el-carousel-item
        v-for="item in bannerData"
        :key="item.sort"
        class="carousel-item"
      >
        <img :src="item.httpUrl" alt="" class="carousel-image" />
        <div class="buttonBox">
          <el-button @click="gotoProductDetail(item.detailCode)">{{
            $t("index.查看详情")
          }}</el-button>
          <el-button v-if="false">{{ $t("index.立即购买") }}</el-button>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { getBanner } from "@/service/api";
import { goto } from "@utils/goto";

export default {
  components: {},
  data() {
    return {
      bannerData: {},
      httpUrls: [],
      swiperOptions: {
        //无限循环
        loop: true,
        //自动轮播
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
          // bulletElement: "li",
          // type: "custom",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
      },
    };
  },
  computed: {},
  mounted() {
    this.Banner();
  },
  methods: {
    async Banner() {
      this.bannerData = (await getBanner()) || {};
      // this.httpUrls = this.data.map((product) => product.httpUrl);
      // console.log(1, this.httpUrls);
      // console.log(this.httpUrls);
    },
    gotoProductDetail(produceName) {
      goto("/productDetail?product=" + produceName);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";
// .tab-class-detail::v-deep {
//   .el-carousel__arrow {
//     display: none;
//   }
// }
.carousel-container {
  position: relative;
  height: auto;
}

.carousel-image {
  width: 100vw; /* 可选，根据需要调整宽度 */
}

.carousel-item {
  .buttonBox {
    position: absolute;
    bottom: 30%;
    left: 18%;
  }
}

.el-button {
  border-radius: 30px;
  font-weight: bold;
  color: black;
}
.el-button:hover {
  background-color: red;
  border: red;
  color: white;
}
</style>
