import Vue from 'vue';

import '@utils/vant';
import '@utils/element';

import '@utils/setRem';
import '@assets/styles/reset.css';
import '@assets/styles/font.css';
import '@assets/iconfont/iconfont.css'

import App from './App.vue';
import store from '@/store'
import i18n from '@/locale'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  i18n
}).$mount('#app')
