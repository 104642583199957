<template>
  <!-- 图表的容器，一定要有宽高 -->
  <div class="chartBox">
    <div id="main" :style="{ width: '100%', height: '100%' }"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "axios";

// var ROOT_PATH = "https://echarts.apache.org/examples";

export default {
  mounted() {
    var chartDom = document.getElementById("main");
    var myChart = echarts.init(chartDom);
    var option;

    axios
      .get("https://ai.modehoist.com/mode/survey/sumbitResult.png")
      .then((response) => {
        echarts.registerMap("iceland_svg", { svg: response.data });
        option = {
          tooltip: {
            trigger: "item",
          },
          geo: {
            map: "world",
            roam: true,
            label: {
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              borderColor: "#333",
            },
            emphasis: {
              itemStyle: {
                areaColor: "#fdebcf",
              },
            },
          },
          series: [
            {
              name: "产品布局情况",
              type: "scatter",
              coordinateSystem: "geo",
              data: [
                // 示例数据点，这里需要经纬度坐标
                {
                  name: "冰岛",
                  value: [-17.666667, 64.133333],
                  symbolSize: 10,
                },
                { name: "美国", value: [-95.712891, 37.09024], symbolSize: 10 },
                { name: "中国", value: [104.06667, 30.57282], symbolSize: 10 },
                // 更多数据点...
              ],
              symbolSize: function (val) {
                // 可以根据数据点的值来设置大小
                return val[2] * 2;
              },
              label: {
                emphasis: {
                  show: true,
                  formatter: "{b}",
                },
              },
            },
          ],
        };
        myChart.setOption(option);
        myChart.getZr().on("click", function (params) {
          var pixelPoint = [params.offsetX, params.offsetY];
          var dataPoint = myChart.convertFromPixel({ geoIndex: 0 }, pixelPoint);
          console.log(dataPoint);
        });
      })
      .catch((error) => {
        console.error(error);
      });

    option && myChart.setOption(option);
  },
};
</script>

<style scoped>
.chartBox {
  width: 30%;
  height: 50vh;
}
</style>
